const requestApi = require("@/lib/request");
// 直播间接口
module.exports = {
    //直播间列表
    roomList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间操作
    roomForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间记录
    roomLogList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-log-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间助理记录
    roomAdminList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-admin-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间助理操作
    roomAdminForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-admin-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //直播间助理删除
    roomAdminDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/room/room-admin-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}