<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_box" ref="pageBox">
          <el-table v-loading="loading" row-key="id" size="small" class="el_table" cell-class-name="el_table_cell"
            header-row-class-name="el_table_header" :data="listData" :height="tableHeight" style="width: 100%">
            <el-table-column label="直播间头像" width="110">
              <template slot-scope="scope">
                <el-image style="width: 100px; height: 100px" :src="scope.row.web_vip_face" fit="contain"
                  v-if="scope.row.web_face_url"></el-image>
                <el-image style="width: 100px; height: 100px" src="/images/image-empty.png" fit="contain"
                  v-else></el-image>
              </template>
            </el-table-column>
            <el-table-column label="直播间封面" width="110">
              <template slot-scope="scope">
                <el-image style="width: 100px; height: 100px" :src="scope.row.web_face_url" fit="contain"
                  v-if="scope.row.web_face_url"></el-image>
                <el-image style="width: 100px; height: 100px" src="/images/image-empty.png" fit="contain"
                  v-else></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="直播间名称" min-width="300"> </el-table-column>
            <el-table-column label="主播">
              <template slot-scope="scope">
                <el-row type="flex" align="middle" v-if="scope.row.vip">
                  <el-col style="width:auto">
                    <el-avatar :src="scope.row.vip.face_url" :size="40"></el-avatar>
                  </el-col>
                  <el-col style="width:auto;padding-left: 10px;">
                    {{ scope.row.vip.name }}
                  </el-col>
                </el-row>
                <div v-else>无</div>
              </template>
            </el-table-column>
            <el-table-column prop="man_num" label="初始人数" width="100">
            </el-table-column>
            <el-table-column label="直播状态" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 1">直播中</div>
                <div v-if="scope.row.status == 2">未直播</div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" align="right" header-align="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button @click="showAdminForm(scope.row)" class="btn" type="text" icon="el-icon-user" size="small">
                  助理
                </el-button>
                <el-button @click="doForm(scope.row)" class="btn" type="text" icon="el-icon-edit" size="small">
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination background layout="total,prev,pager,next,jumper" @current-change="handleCurrentChange"
              :total="pageConfig.counts" :page-size="pageConfig.pageSize" :current-page="pageConfig.pageIndex">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-drawer title="直播间编辑" size="80%" :visible.sync="drawer" direction="rtl" :wrapperClosable="false"
      :close-on-press-escape="false">
      <div class="drawer_box">
        <el-form ref="modelForm" :rules="modelRules" :model="modelForm" label-width="100px" size="small">
          <el-form-item label="直播间名称" size="small" prop="title">
            <el-input v-model="modelForm.title" style="width: 300px" placeholder="直播间名称"></el-input>
          </el-form-item>
          <el-form-item label="直播间头像" size="small" prop="vip_face">
            <el-button size="small" style="width: 100px" @click="getResources('vip_face')">+选择图片</el-button>
          </el-form-item>
          <el-form-item label="" size="small" prop="web_vip_face" class="no-bottom">
            <div class="more_img_box">
              <div class="img_item one_img_item">
                <el-image class="img" :src="
                  modelForm.web_vip_face
                    ? modelForm.web_vip_face
                    : '/images/image-empty.png'
                " fit="contain"></el-image>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            图片建议大小：200px*200px
          </el-form-item>
          <el-form-item label="直播间封面" size="small" prop="face_url">
            <el-button size="small" style="width: 100px" @click="getResources('face_url')">+选择图片</el-button>
          </el-form-item>
          <el-form-item label="" size="small" prop="web_face_url" class="no-bottom">
            <div class="more_img_box">
              <div class="img_item one_img_item">
                <el-image class="img" :src="
                  modelForm.web_face_url
                    ? modelForm.web_face_url
                    : '/images/image-empty.png'
                " fit="contain"></el-image>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            图片建议大小：500px*400px
          </el-form-item>
          <el-form-item label="主播" size="small" prop="vip_id">
            <el-button size="small" style="width: 100px" @click="selectVipWin('room')">+选择主播</el-button>
          </el-form-item>
          <el-form-item label="" size="small" v-if="roomVip != null">
            <el-row type="flex" align="middle">
              <el-col style="width:auto">
                <el-avatar :src="roomVip.face_url" :size="40"></el-avatar>
              </el-col>
              <el-col style="width:auto;padding-left: 10px;">
                {{ roomVip.name }}
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="初始化人数" size="small" prop="man_num" class="no-bottom">
            <el-input v-model="modelForm.man_num" placeholder="初始化人数"></el-input>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            直播开始时默认显示人数
          </el-form-item>
          <el-form-item label="直播间描述" size="small" prop="msg">
            <el-input v-model="modelForm.msg" style="width: 400px" placeholder="直播间描述"></el-input>
          </el-form-item>
          <el-form-item label="直播间公告" size="small" prop="notice">
            <el-input v-model="modelForm.notice" style="width: 400px" placeholder="直播间公告"></el-input>
          </el-form-item>
          <div class="drawer_footer" style="left: 20%">
            <el-button type="primary" size="small" native-type="submit" @click.native.prevent="handleSubmit"
              :loading="btnLogining">提交</el-button>
            <el-button size="small" @click="drawer = false">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
    <el-drawer title="直播间助理" size="80%" :visible.sync="adminDrawer" direction="rtl" :wrapperClosable="false"
      :close-on-press-escape="false">
      <div class="drawer_box">
        <div class="page_search">
          <el-row type="flex">
            <el-col class="search_col">
              <el-button type="primary" size="small" @click="selectVipWin('admin')"
                :loading="btnLogining">新增助理</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="page_box">
          <el-table v-loading="adminLoading" size="small" class="el_table" cell-class-name="el_table_cell"
            header-row-class-name="el_table_header" :data="adminList" style="width: 100%">
            <el-table-column label="助理">
              <template slot-scope="scope">
                <el-row type="flex" align="middle">
                  <el-col style="width:auto">
                    <el-avatar :src="scope.row.face_url" :size="40"></el-avatar>
                  </el-col>
                  <el-col style="width:auto;padding-left: 10px;">
                    {{ scope.row.name }}
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column fixed="right" align="right" header-align="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button @click="deleteAdmin(scope.row)" class="btn" type="text" icon="el-icon-delete" size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
    <ResourceWin ref="ResourceWin" v-if="reVisible" :resType="1" :isMore="false" @closeResourceWin="reVisible = false"
      @setResources="setResources" />
    <VipWin ref="VipWin" v-if="vipVisible" @closeWin="vipVisible = false" @setVips="setVips" />
  </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import roomApi from "@/api/room";
import ResourceWin from "@/components/resource/win";
import VipWin from "@/components/vip/win";
export default {
  components: {
    ResourceWin,
    VipWin
  },
  data() {
    return {
      loading: false,
      btnLogining: false,
      adminLoading: false,
      drawer: false,
      adminDrawer: false,
      searchForm: {
        key: ""
      },
      model: null,
      listData: [],
      pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
      roomVip: null,
      modelForm: {
        vip_face: "",
        web_vip_face: "",
        face_url: "",
        web_face_url: "",
        title: "",
        msg: "",
        man_num: 10000,
        vip_id: null,
        notice: "",
      },
      modelRules: {
        title: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 1, max: 100, message: "长度不超过100个字符" },
        ],
        vip_face: [
          { required: true, message: "请选择", trigger: "blur" }
        ],
        face_url: [
          { required: true, message: "请选择", trigger: "blur" }
        ],
        msg: [
          { min: 0, max: 100, message: "长度不超过100个字符" },
        ],
        man_num: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        vip_id: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        notice: [
          { min: 0, max: 100, message: "长度不超过100个字符" },
        ],
      },
      adminList: [],
      reVisible: false,
      vipVisible: false,
      tableHeight: 500
    };
  },
  _relType: '',
  _vipType: '',
  created() { },
  mounted() {
    onTableHeight(this);
    this.getList();
  },
  methods: {
    //列表
    getList(pageIndex = 1) {
      this.loading = true;
      roomApi
        .roomList({
          ...this.searchForm,
          page_size: this.pageConfig.pageSize,
          page_index: pageIndex,
        })
        .then((res) => {
          this.listData = res.list;
          this.pageConfig.pageIndex = res.page.page_index;
          this.pageConfig.counts = res.page.counts;
        })
        .catch(() => { })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.getList(val);
    },
    selectVipWin(type) {
      this._vipType = type;
      this.vipVisible = true;
    },
    setVips(data) {
      if (data.list.length > 0) {
        if (this._vipType == 'room') {
          this.roomVip = data.list[0];
          this.modelForm.vip_id = this.roomVip.id;
          this.vipVisible = false;
        } else if (this._vipType == 'admin') {
          this.addAdmin(data.list[0].id);
          this.vipVisible = false;
        }
      }

    },
    addAdmin(vipID) {
      this.btnLogining = true;
      roomApi
        .roomAdminForm({
          id: this.model ? this.model.id : 0,
          vip_id: vipID
        })
        .then((res) => {
          this.$message({
            message: "提交成功",
            type: "success",
            offset: 60,
          });
          this.getAdminList();
        })
        .catch(() => { })
        .finally(() => {
          this.btnLogining = false;
        });
    },
    //删除
    deleteAdmin(row) {
      this.$confirm("确定是否删除该助理?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          roomApi
            .roomAdminDelete({
              id:this.model.id,
              vip_id: row.id,
            })
            .then((res) => {
              message.close();
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getAdminList();
            })
            .catch((res) => {
              message.close();
            });
        })
        .catch(() => { });
    },
    getResources(type) {
      this._relType = type;
      this.reVisible = true;
    },
    //设置资源回调方法
    setResources(data) {
      if (this._relType == 'vip_face') {
        this.modelForm.vip_face = data.files[0].path;
        this.modelForm.web_vip_face = data.files[0].web_path;
      } else if (this._relType == 'face_url') {
        this.modelForm.face_url = data.files[0].path;
        this.modelForm.web_face_url = data.files[0].web_path;
      }
      this.reVisible = false;
    },
    doForm(row) {
      this.model = row;
      this.$nextTick(() => {
        if (this.model != null) {
          this.modelForm.title = this.model.title;
          this.modelForm.vip_face = this.model.vip_face;
          this.modelForm.web_vip_face = this.model.web_vip_face;
          this.modelForm.face_url = this.model.face_url;
          this.modelForm.web_face_url = this.model.web_face_url;
          this.modelForm.msg = this.model.msg;
          this.modelForm.man_num = this.model.man_num;
          this.modelForm.vip_id = this.model.vip_id;
          this.modelForm.notice = this.model.notice;
          this.roomVip = this.model.vip;
        }
      });
      this.drawer = true;
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;
          roomApi
            .roomForm({
              id: this.model ? this.model.id : 0,
              ...this.modelForm
            })
            .then((res) => {
              this.drawer = false;
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.getList(1);
            })
            .catch(() => { })
            .finally(() => {
              this.btnLogining = false;
            });
        }
      })
    },
    showAdminForm(row) {
      this.adminDrawer = true;
      this.adminLoading = true;
      this.model = row;
      this.getAdminList();
    },
    getAdminList() {
      roomApi
        .roomAdminList({
          room_id: this.model.id
        })
        .then((res) => {
          this.adminList = res.list;
        })
        .catch(() => { })
        .finally(() => {
          this.adminLoading = false;
        });
    },
  },
};
</script>
<style scope></style>
